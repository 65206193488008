import iconCheckGreenFilled from '../../assets/images/icons/icon-check-green-filled-40.png';
import iconCheckGreen from '../../assets/images/icons/icon-check-green-40.png';
import iconCheckWhite from '../../assets/images/icons/icon-check-white-40.png';
import iconArrowLeft from '../../assets/images/icons/icon-arrow-left.png';

export const useStyles = (theme) => ({
	cursorPointer: {
		cursor: 'pointer',
	},
	topBanner: {
		background: '#D65050',
		padding: 10,
		boxSizing: 'border-box',
	},
	topBannerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: 1350,
	},
	topBannerText: {
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '28px',
		color: '#ffffff',
		'& a':  {
			color: '#ffffff',
		}
	},
	topBannerCTA: {
		marginLeft: 10,
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '17px',
		color: '#ffffff',
		background: '#FF8F00',
		boxShadow: '0px 0px 6px rgba(40, 51, 57, 0.3)',
		borderRadius: 20,
		padding: 11,
		minWidth: 150,
		textAlign: 'center',
	},
	bannerWrapper: {
		marginLeft: '-15px',
		marginRight: '-15px',
	},
	featuredOn: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		justifyContent: 'center',
		color: '#223770',
		fontSize: 22,
		fontWeight: 700,
		'& span': {
			marginRight: 10,
		}
	},
	vpnOverviewLogo: {
		maxWidth: 160,
	},
	pageWrapper: {
		padding: 48,
		marginTop: 22,
		marginBottom: 32,
		background: '#ffffff',
		borderRadius: 16,
	},
	pageHeader: {
		padding: theme.spacing(7, 0, 4),
		color: '#283339',
		fontFamily: 'Spartan, sans-serif',
		'& h1': {
			fontSize: 44,
			fontWeight: 700,
			lineHeight: '58px',
			letterSpacing: '-1px',
			margin: '0 0 10px 0',
		},
		'& h2': {
			fontSize: 18,
			fontWeight: 600,
			lineHeight: '30px',
			letterSpacing: '-1px',
			margin: '0 auto',
		},
		'& p': {
			fontSize: 18,
			fontWeight: 700,
			color: '#6d7173',
			fontFamily: 'Spartan, sans-serif',
			marginBottom: 30,
		}
	},
	contentHeader: {
		color: '#283339',
		fontSize: 36,
		fontWeight: 700,
		lineHeight: '36px',
		padding: '10px 15px 20px',
		marginBottom: 20,
	},
	flexBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	interstitialSteps: {
		maxWidth: 1000,
		margin: '0 auto',
		fontSize: 14,
		lineHeight: '22px',
		color: '#283339',
		fontWeight: 600,
		fontFamily: 'Spartan, sans-serif',
	},
	interstitialStep: {
		display: 'flex',
		alignItems: 'center',
		'& span': {
			display: 'block',
			minWidth: 40,
			height: 40,
			backgroundSize: '100%',
			marginTop: '-3px',
			marginRight:  7,
		}
	},
	interstitialStepLine: {
		height: 2,
		margin: '5px 20px',
		background: '#D4D6D7',
		flexGrow: 1,
	},
	interstitialStepLineGreen: {
		background: '#12AF5A',
	},
	stepPast: {
		cursor: 'pointer',
	},
	stepCurrent: {
		
	},
	stepNext: {
		color: '#93999C',
	},
	interstitialStepCheckGreenFilled: {
		backgroundImage: `url(${iconCheckGreenFilled})`,
	},
	interstitialStepCheckGreen: {
		backgroundImage: `url(${iconCheckGreen})`,
	},
	interstitialStepCheckWhite: {
		backgroundImage: `url(${iconCheckWhite})`,
	},
	interstitialActions: {
		maxWidth: 1070,
		margin: '0 auto',
		fontSize: 14,
		lineHeight: '22px',
		color: '#93999C',
		fontWeight: 600,
		fontFamily: 'Spartan, sans-serif',
	},
	arrowLeft: {
		position: 'relative',
		paddingLeft: 12,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 5,
			left: 0,
			width: 6,
			height: 10,
			backgroundImage: `url(${iconArrowLeft})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
	},
	regularVersion: {
		paddingTop: 0,
		paddingLeft: 0,
		paddingRight: 0,
	},
	btnsContainer: {
		paddingBottom: 20,
	},
	btnOrange: {
		margin: 5,
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '17px',
		color: '#ffffff',
		background: '#FF8F00',
		boxShadow: '0px 0px 6px rgba(40, 51, 57, 0.3)',
		borderRadius: 50,
		padding: '15px 30px',
		minWidth: 150,
		textAlign: 'center',
		textDecoration: 'none',
	},
	btnTransparent: {
		margin: 5,
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '17px',
		color: '#93999C',
		borderRadius: 50,
		padding: '15px 30px',
		minWidth: 150,
		textAlign: 'center',
		textDecoration: 'none',
	},
	'@media (max-width: 800px)': {
		topBannerContainer: {
			flexDirection: 'column',
		},
		topBannerText: {
			textAlign: 'center',
		},
		topBannerCTA: {
			marginLeft: 0,
		},
	},
	'@media (max-width: 460px)': {
		pageWrapper : {
			padding: '20px 10px',
		},
		pageHeader: {
			padding: theme.spacing(6, 0, 1),
			'& h1': {
				fontSize: 28,
				lineHeight: '40px',
			}
		},
	}
});